import React, { useEffect, useState } from "react"
import SEO from "../seo"
import {
  AnyQuestions,
  background,
  Description,
  Highlight,
  HowMuch,
  MainSection,
  primary,
  SectionModel,
  surface,
  Warranty,
} from "./index"
import { URL_HOTMART_EBOOK_PRODUTIVIDADE } from "../../constants"
import { getQueryParam, getTrackingHotmart } from "../../utils/query"
import { getTextCupom, formatToMoney } from "../../utils/cupom"
const ebookProdutividade = require("../../assets/images/ebookProdutividade.png")

export default function Produtividade() {
  const [cupom, setCupom] = useState(null)
  const [utm, setUtm] = useState('')

  useEffect(() => {
    setCupom(getQueryParam("cupom"))
    setUtm(getQueryParam("utm_source"))
  }, [])

  function getUrlCheckout(){
    let url = URL_HOTMART_EBOOK_PRODUTIVIDADE
    if(cupom){
      url = url.concat("?offDiscount=" + cupom)
    }
    if(utm){
      const prefix = url.includes("?") ? "&": "?"
      url = url.concat(prefix+'sck='+getTrackingHotmart())
    }
    return url
  }

  function getInfo() {
    const discontPercentage = cupom
      ? Number(cupom.replace(/\D/g, "") || "0")
      : 0
    const price = 39.99
    return {
      // URL_CHECKOUT: isBlackFriday ? 'https://pay.hotmart.com/D61045485C?off=6949m6y1&offDiscount=BLACKNOVEMBER40': URL_HOTMART_EBOOK_PRODUTIVIDADE,
      URL_CHECKOUT: getUrlCheckout(),
      oldPrice: "99,70",
      price: formatToMoney(price),
      newPrice: cupom
        ? formatToMoney(price * (1 - discontPercentage / 100))
        : formatToMoney(price),
      textCupom: cupom
        ? getTextCupom(cupom) ||
        (discontPercentage
          ? `${discontPercentage}% DE DESCONTO! APROVEITE! 🔥`
          : null)
        : null,
      discontPercentage,
    }
  }
  const {
    URL_CHECKOUT,
    price,
    newPrice,
    oldPrice,
    textCupom,
    discontPercentage,
  } = getInfo()

  const sendEvent = (event)=>{
    if(window){
      if(window.fbq) window.fbq('track', event);             
      if(window.gtag)window.gtag("event", event);
    }
  }

  const onCheckout = () => {
    sendEvent("click_checkout_ebook_produtividade")
  }

  return (
    <>
      <SEO
        title="Guia Definitivo para a Produtividade!"
        pageId="ebook_produtividade"
      />
      <MainSection
        img={ebookProdutividade}
        title={
        <>
            Este é o único guia que você precisa para <Highlight>ser mais produtivo</Highlight>
        </>
        }
        description={
          <>
            Sem lista de tarefas gigantes. Sem ser multitarefa. Sem trabalhar sem pausas. E com muito mais qualidade de vida e tempo livre para fazer o que você quiser.
          </>
        }
        textCupom={textCupom}
        cta={'QUERO SER MAIS PRODUTIVO!'}
      />
      <SectionModel
        id="2"
        background={surface}
        title="O que acontece quando você NÃO é produtivo?"
        list={
          <>
            <Description>
              • Seu dia não rende; <br /><br />
              • Procrastina muito mais; <br /><br />
              • Tenta compensar sendo multitarefa, o que dá totalmente errado;<br /><br />
              • As semanas e os meses passam e você está no mesmo lugar;<br /><br />
              • Não consegue alcançar seus sonhos.<br /><br /><br />
              Fala a verdade: você já se sentiu assim, não é?
            </Description>
          </>
        }
      />
      <SectionModel
        id="3"
        background={background}
        title="Para quem é o Guia Definitivo para a Produtividade?"
        list={
          <Description>
            Nós criamos este guia para você que deseja:<br /><br /><br />
            ✔️ Ser mais produtivo e ter mais tempo livre; <br /><br />
            ✔️ Driblar a procrastinação;<br /><br />
            ✔️ Ter uma rotina mais organizada e leve;<br /><br />
            ✔️ Ter mais consistência em seus projetos;<br /><br />
            ✔️ Alcançar seus sonhos e objetivos;<br /><br />
            ✔️ Construir uma versão incrível de si mesmo.<br /><br />
          </Description>
        }
      />
      {/* <SectionModel
        id="4"
        background={surface}
        title="Adquira o Guia Definitivo para a Produtividade se você deseja:"
        list={
          <ul>
            <li>Se tornar mais produtivo e ter mais tempo livre;</li>
            <li>Descobrir como driblar a procrastinação;</li>
            <li>Ter uma rotina mais organizada e leve;</li>
            <li>Alcançar seus sonhos e objetivos;</li>
            <li>Viver uma versão incrível de si mesmo.</li>
          </ul>
        }
      /> */}
      <SectionModel
        id="5"
        background={surface}
        title={"O que você vai receber?"}
        img={ebookProdutividade}
        list={
          <Description>
            Adquira o guia agora mesmo e tenha acesso a:<br /><br /><br />
            ✔️ 23 técnicas para ser mais produtivo;<br /><br />
            ✔️ 5 bônus de conteúdos com dicas extras;<br /><br />
            ✔️ 6 bônus exclusivos em PDF;<br /><br />
            ✔️ 140+ páginas de conteúdo e bônus.<br /><br />
          </Description>
        }
      />
      <SectionModel
        id="checkout"
        background={background}
        title={"O Guia Definitivo para Produtividade é um compilado de diversas técnicas e conhecimentos sobre produtividade "}
        list={
          <>
            <Description style={{ marginTop: "3rem" }}>
              Isso significa que, para que você tivesse acesso a todo esse conhecimento, você deveria comprar e ler diversos livros e fazer diversos cursos.<br /><br />

              Ou seja, o preço que você estará pagando por este ebook não é nada comparado ao valor que ele pode te trazer.<br /><br />

              Afinal, coisas como liberdade, uma rotina mais organizada e leve e qualidade de vida são coisas que o dinheiro não paga!<br /><br />

              Lembre-se: que esse não é só um ebook e sim a oportunidade de transformar sua vida, dominando a produtividade e driblando a procrastinação.<br /><br />
            </Description>

            <HowMuch
              discontPercentage={discontPercentage}
              oldPrice={oldPrice}
              price={price}
              newPrice={newPrice}
              urlCheckout={URL_CHECKOUT}
              onClick={onCheckout}
            />
          </>
        }
      />

      <Warranty id="7" background={surface} />
      <AnyQuestions id="8" />
    </>
  )
}
